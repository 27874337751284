// extracted by mini-css-extract-plugin
export var bawhWrapper = "aqb-module--bawhWrapper--UHcYP";
export var chapter = "aqb-module--chapter--BnipV";
export var twoUp = "aqb-module--twoUp--m+7gu";
export var textBox = "aqb-module--textBox--m4soJ";
export var twoColumn = "aqb-module--twoColumn--OBk1L";
export var left = "aqb-module--left--JdOwk";
export var oneUp = "aqb-module--oneUp--NUnXh";
export var yomWrapper = "aqb-module--yomWrapper--myZCs";
export var button = "aqb-module--button--cCwpH";
export var resourceWrapper = "aqb-module--resourceWrapper--Ah3+z";